<template>
  <section class="content-center">
    <div v-if="hasSubscriptions">
      <available-marathons :subscribedProducts=true></available-marathons>
    </div>
    <div v-else>
      <no-products-purchased></no-products-purchased>
    </div>
  </section>
</template>
<script>
import ProductListView from '../components/ProductListView.vue'
import AvailableMarathons from './AvailableMarathons.vue'
import NoProductsPurchased from './NoProductsPurchased.vue'
import store from '@/store'
import _ from 'lodash'

import {
  mdiSeal,
  mdiLockOpenOutline,
  mdiLockOutline,
  mdiLock
} from '@mdi/js'

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'My Marathons'
  },
  components: {
    ProductListView,
    NoProductsPurchased,
    AvailableMarathons,
  },
  data () {
    return {
      icons: {
        mdiLockOpenOutline,
        mdiLockOutline,
        mdiLock,
        mdiSeal,
      },
    };
  },
  computed: {
    marathons () {
      return store.state.products.marathon;
    },
    hasSubscriptions () {
      if(_.isEmpty(store.state.user.user.subscriptions )){
        return false
      } else {
        return true
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
